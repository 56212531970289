import { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';

const VideoPlayer = (props) => {

  const { url, setIsPlaying, autoHeight } = props;
  const [aspectRatio, setAspectRatio] = useState(0.5625);

  const player = useRef();
  const container = useRef();
  const videoPlayer = useRef();

  if (url) {
    return (
      <div
        ref={container}
        className="video-player__container"
      >
        <div
          className={`video-player${url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? ' video-player--video-element' : ''}${autoHeight === true ? ' height--auto' : ''}`}
          ref={videoPlayer}
          style={{
            height: autoHeight === true || !container.current ?
              'auto' :
                container.current.offsetHeight / container.current.offsetWidth > aspectRatio ? '100%' : container.current.offsetWidth / aspectRatio + 'px',
            width: autoHeight === true || !container.current ? '100%' :
              container.current.offsetHeight / container.current.offsetWidth > aspectRatio ? container.current.offsetHeight / aspectRatio + 'px' : '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%'
          }}
        >
          <div className="video__wrapper">
            <ReactPlayer
              ref={player}
              url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
              className="video-player__player"
              playsInline={true}
              playing={true}
              volume={0}
              muted={true}
              height={'100%'}
              width={'100%'}
              loop={true}
              allow="autoplay"
              onPlay={() => setIsPlaying && setIsPlaying(true)}
              onPause={() => setIsPlaying && setIsPlaying(false)}
              onCanPlay={(e) => {
                if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
                  if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                    setAspectRatio(player.current.getInternalPlayer().videoWidth / player.current.getInternalPlayer().videoHeight);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default VideoPlayer;