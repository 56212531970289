import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import VideoPlayer from '../VideoPlayer';

const ArticleLayoutAudio = (props) => {

  const { article } = props;
  const { title, subtitle, excerpt, audioEmbed, content } = article;

  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12 article__col--video no-padding">
            {
              audioEmbed &&
              <VideoPlayer url={audioEmbed} />  
            }
          </div>
          <div className="col-xxs-12">
          <div className="article__title__wrapper">
            <h2 className="article-thumbnail__title">{title}</h2>
            <h3 className="article-thumbnail__subtitle">{subtitle}</h3>
          </div>
          {
            excerpt &&
            <div className="featured__intro">
              <ReactMarkdown>{excerpt}</ReactMarkdown>
            </div>
            }
            {
              content && content !== '' &&
              <div className="text__wrapper article__text__wrapper">
                {parse(content)}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleLayoutAudio;