import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './Header';
import Preview from '../utils/Preview';
import Shop from '../views/Shop';
import Editions from '../views/Editions';
import Search from './Search';
import Archive from '../views/Archive';
import Submit from '../views/Submit';
import Page from '../views/Page';
import Article from '../views/Article';
import Edition from '../views/Edition';
import Product from '../components/Product';
import Homepage from '../views/Homepage';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import InitialiseAnimation from './InitialiseAnimation';
import CookiesNotice from './CookiesNotice';

const Content = (props) => {

  const { location, windowWidth, windowHeight, previewItem, homepage, pages, editions, articles,
    searchIsActive, searchHeight, setScrollAmount, menuIsActive, isInitialised, fetchContent, cookiesAreAccepted, setCookiesAreAccepted } = props;

  const handleScroll = (e) => {
    setScrollAmount(e.target.scrollTop);
  }

  return (
    <div
      className={`wrapper`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      {
        window.location.origin.indexOf('http://localhost:') === 0 &&
        <button
          className="fetch-button"
          onClick={fetchContent}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999
          }}
        >Fetch content</button>
      }
      <Header {...props} />
      {
        (location.pathname === '/search' || location.pathname === '/search/' || searchIsActive === true) &&
        <Search {...props} key="search" />
      }
      <AnimatePresence>
        {
          menuIsActive === true && windowWidth < 768 &&
          <MobileMenu {...props} />
        }
      </AnimatePresence>
      <Preview {...props} />
      <main
        className="main"
        onScroll={handleScroll}
        style={{
          height: windowHeight + 'px',
          transform: searchIsActive === true && location.pathname !== '/search' ? `translate(-50%, ${searchHeight}px)` : null,
          paddingBottom: searchIsActive === true ? `${searchHeight}px` : null
        }}
      >
        {
          !previewItem.collection &&
          <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={<Homepage {...props} page={homepage} />}
              />
              <Route
                path="/shop"
                element={<Shop {...props} />}
              />
              <Route
                path="/archive"
                element={<Archive {...props} />}
              />
              <Route
                path="/editions"
                element={<Editions {...props} />}
                />
                <Route
                  path="/submit"
                  element={<Submit {...props} />}
                />
              {
                pages &&
                pages[0] &&
                pages.map(
                  (page, index) => (
                    <Route
                      key={`page ${index}`}
                      path={`/page/${page.name_slug}`}
                      element={<Page {...props} page={page} />}
                    />
                  )
                )
              }
              {
                articles &&
                articles[0] &&
                articles.map(
                  (article, index) => (
                    <Route
                      key={`article ${index}`}
                      path={`/article/${article.title_slug}`}
                      element={<Article {...props} article={article} />}
                    />
                  )
                )
              }
              {
                editions &&
                editions[0] &&
                editions.map(
                  (edition, index) => (
                    <Route
                      key={`edition ${index}`}
                      path={`/editions/${edition.title_slug}`}
                      element={<Edition {...props} edition={edition} />}
                    />
                  )
                )
              }
              <Route
                path="/search"
                element={<div />}
              />
            </Routes>
          </AnimatePresence>
        }
        {
          previewItem.collection === 'pages' &&
          <Page {...props} page={previewItem} />
        }
        {
          previewItem.collection === 'articles' &&
          <Article {...props} article={previewItem} />
        }
        {
          previewItem.collection === 'editions' &&
          <Edition {...props} edition={previewItem} />
        }
        {
          previewItem.collection === 'products' &&
          <Product {...props} product={previewItem} />
        }
        <Footer {...props} />
      </main>
      <AnimatePresence>
        {
          isInitialised === false &&
          <InitialiseAnimation {...props} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          cookiesAreAccepted === false &&
          <CookiesNotice handleAcceptCookies={() => setCookiesAreAccepted(true)} />
        }
      </AnimatePresence>
    </div>
  )
}

export default Content;